import {useBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import Image from 'next/image';
import React, {FC} from 'react';

import {TypoComponentsContentSectionFramedImage} from 'lib/api/strapi';

import {Box, Grid, Typography} from 'components/basic-components';
import {ButtonWithConfig} from 'components/basic-components/Button/ButtonWithConfig';
import {Carousel} from 'components/basic-components/Carousel/Carousel';
import MarkdownContent from 'components/basic-components/MarkdownContent';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './ContentSectionFramedImage.module.scss';

const ContentSectionFramedImage: FC<TypoComponentsContentSectionFramedImage> = ({
  title,
  titleLevel,
  subtitle,
  subTitleLevel,
  text,
  image,
  imagePosition,
  button,
  displayImageFrame,
  alignItems,
}) => {
  const {textColor, imageFrame} = useBackgroundStyleConfigs();
  const isSlider = image?.data?.length > 1;

  return (
    <Grid
      className={styles.wrapper}
      sx={{
        flexDirection: {
          xxs: 'column-reverse',
          md: imagePosition === 'left' ? 'row' : imagePosition === 'right' ? 'row-reverse' : null,
          alignItems:
            alignItems === 'start' ? 'flex-start' : alignItems === 'center' ? 'center' : null,
          color: textColor,
        },
      }}
      container
      rowSpacing={'50px'}
      columnSpacing={{xxs: 0, md: '2%'}}
    >
      <Grid xxs={12} md={6}>
        <Box>
          {isSlider ? (
            <Carousel images={image?.data} autoPlayInterval={5000} />
          ) : (
            <Image
              className={styles.image}
              src={image?.data[0]?.attributes?.url}
              alt=""
              height={image?.data[0]?.attributes?.height}
              width={image?.data[0]?.attributes?.width}
              style={{
                boxShadow: displayImageFrame
                  ? imagePosition === 'right'
                    ? `10px -10px 0 0 ${imageFrame}`
                    : imagePosition === 'left'
                    ? `-10px -10px 0 0 ${imageFrame}`
                    : null
                  : null,
                marginLeft: displayImageFrame && imagePosition === 'left' ? '10px' : null,
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid xxs={12} md={6}>
        {title ? (
          <Typography
            className={styles.title}
            sx={{typography: {xxs: 'titleMobile', md: 'h1'}}}
            component={titleLevel}
          >
            {title}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography
            className={styles.subtitle}
            sx={{typography: {xxs: 'footerCards', md: 'h2'}}}
            component={subTitleLevel}
          >
            {subtitle}
          </Typography>
        ) : null}

        <MarkdownContent className={styles.markdown} content={text} />
        <ButtonWithConfig buttonProps={button} useThemeStyle />
      </Grid>
    </Grid>
  );
};

export default withErrorBoundary(ContentSectionFramedImage, ' ContentSectionFramedImage');
